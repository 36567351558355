import Rstorage from "../../assets/Pshoot/RED/Rstorage.png";
import Rlamp from "../../assets/Pshoot/RED/Rlamp.png";
import Rbrake from "../../assets/Pshoot/RED/Rbrake.png";
import Rtyre from "../../assets/Pshoot/RED/Rtyre.png";
import r1 from "../../assets/Pshoot/RED/r1.png";
import r2 from "../../assets/Pshoot/RED/r2.png";
import r3 from "../../assets/Pshoot/RED/r3.png";
import r4 from "../../assets/Pshoot/RED/r4.png";
import w1 from "../../assets/Pshoot/White/w1.png";
import w2 from "../../assets/Pshoot/White/w2.png";
import w3 from "../../assets/Pshoot/White/w3.png";
import w4 from "../../assets/Pshoot/White/w4.png";
import Gp from "../../assets/Pshoot/Grey/Gp.png";
import Gpstorage from "../../assets/Pshoot/Grey/Gpstorage.png";
import Gpbrake from "../../assets/Pshoot/Grey/Gpbrake.png";
import Gptyre from "../../assets/Pshoot/Grey/Gptyre.png";
import Gplamp from "../../assets/Pshoot/Grey/Gplamp.png";

import g1 from "../../assets/Pshoot/LEOPARD/Gray/g1.png";
import g2 from "../../assets/Pshoot/LEOPARD/Gray/g2.png";
import g3 from "../../assets/Pshoot/LEOPARD/Gray/g3.png";
import g4 from "../../assets/Pshoot/LEOPARD/Gray/g4.png";
import gre1 from "../../assets/Pshoot/LEOPARD/Green/gre1.png";
import gre2 from "../../assets/Pshoot/LEOPARD/Green/gre2.png";
import gre3 from "../../assets/Pshoot/LEOPARD/Green/gre3.png";
import gre4 from "../../assets/Pshoot/LEOPARD/Green/gre4.png";
import Grstorage from "../../assets/Pshoot/LEOPARD/Gray/Grstorage.png";
import Grlamp from "../../assets/Pshoot/LEOPARD/Gray/Grlamp.png";
import Grbrake from "../../assets/Pshoot/LEOPARD/Gray/Grbrake.png";
import Grtyre from "../../assets/Pshoot/LEOPARD/Gray/Grtyre.png";
import Fgrestorage from "../../assets/Pshoot/LEOPARD/Green/Fgrestorage.png";
import Fgretyre from "../../assets/Pshoot/LEOPARD/Green/Fgretyre.png";
import Fgrebrake from "../../assets/Pshoot/LEOPARD/Green/Fgrebrake.png";
import Fgrelamp from "../../assets/Pshoot/LEOPARD/Green/Fgrelamp.png";
import storage from "../../assets/Pshoot/FWhite/storage.png";
import tyre from "../../assets/Pshoot/FWhite/tyre.png";
import diskbrake from "../../assets/Pshoot/FWhite/diskbrake.png";
import daylight from "../../assets/Pshoot/FWhite/daylight.png";
import digitalmeter from "../../assets/Pshoot/FWhite/digitalmeter.png";
import cruidecontrol from "../../assets/Pshoot/FWhite/cruidecontrol.png";
import Rj1 from "../../assets/Pshoot/JOY/Rj1.png";
import Rj2 from "../../assets/Pshoot/JOY/Rj2.png";
import Wj1 from "../../assets/Pshoot/JOY/Wj1.png";
import JG1 from "../../assets/Pshoot/JOY/JG1.png";
import Jtyre from "../../assets/Pshoot/JOY/Jtyre.png";
import Jstorage from "../../assets/Pshoot/JOY/Jstorage.png";
import JGlamp from "../../assets/Pshoot/JOY/JGlamp.png";
import Jbrake from "../../assets/Pshoot/JOY/Jbrake.png";
import LMNew from "../../assets/Pshoot/LEOPARD/Gray/LMNew.jpg";
import LCnew from "../../assets/Pshoot/LEOPARD/Gray/LCnew.png";
import LW1 from '../../assets/images/leopard (2).png'
import LW2 from "../../assets/images/leopard (3).png";
import LW3 from "../../assets/images/leopard (4).png";



import LP1 from "../../assets/Pshoot/LEOPARD_PRO/LP1.png";
import LP2 from "../../assets/Pshoot/LEOPARD_PRO/LP2.png";
import LP3 from "../../assets/Pshoot/LEOPARD_PRO/LP3.png";
import LP4 from "../../assets/Pshoot/LEOPARD_PRO/LPW4.png";
import LP5 from "../../assets/Pshoot/LEOPARD_PRO/LP5.png";
import LPW_Main from '../../assets/images/LPW_Main.png';



import ZB1 from "../../assets/Pshoot/ZIPPY/ZB1.png";
import ZB2 from "../../assets/Pshoot/ZIPPY/ZB2.png";
import Z3 from "../../assets/Pshoot/ZIPPY/Z3.png";
import Z4 from "../../assets/Pshoot/ZIPPY/Z4.png";
import Z5 from "../../assets/Pshoot/ZIPPY/Z5.png";
import Z6 from "../../assets/Pshoot/ZIPPY/Z6.png";
import Z7 from "../../assets/Pshoot/ZIPPY/Z7.png";
import Z8 from "../../assets/Pshoot/ZIPPY/Z8.png";




import F1 from "../../assets/Pshoot/Flora/F1.png";
import F2 from "../../assets/Pshoot/Flora/F2.jpg";
import F3 from "../../assets/Pshoot/Flora/F3.png";
import F4 from "../../assets/Pshoot/Flora/F4.png";
import F5 from "../../assets/Pshoot/Flora/F5.jpg";
import F6 from "../../assets/Pshoot/Flora/F6.jpg";
import F7 from "../../assets/Pshoot/Flora/F7.jpg";



import P2 from "../../assets/Pshoot/Pearl/P2.png";
// import P3 from "../../assets/Pshoot/Pearl/P3.png";

import P4 from "../../assets/Pshoot/Pearl/P4.png";
import P5 from "../../assets/Pshoot/Pearl/P5.png";
import P6 from "../../assets/Pshoot/Pearl/P6.jpg";
import P7 from "../../assets/Pshoot/Pearl/P7.png";
import P8 from "../../assets/Pshoot/Pearl/P8.png";
import P9 from "../../assets/Pshoot/Pearl/P9.jpg";
import P3 from "../../assets/Pshoot/Pearl/P10.jpg";
import P11 from "../../assets/Pshoot/Pearl/P11.png";
import P12 from "../../assets/Pshoot/Pearl/P12.png";
import P13 from "../../assets/Pshoot/Pearl/P13.png";
import P14 from "../../assets/Pshoot/Pearl/P14.jpg";



import J1 from "../../assets/Pshoot/New/J1.jpg";
import J2 from "../../assets/Pshoot/New/J2.jpg";
import J3 from "../../assets/Pshoot/New/J3.jpg";
import J4 from "../../assets/Pshoot/New/J4.jpg";
import J5 from "../../assets/Pshoot/New/J5.jpg";
import J6 from "../../assets/Pshoot/New/J6.jpg";
import J7 from "../../assets/Pshoot/New/J7.jpg";
import J8 from "../../assets/Pshoot/New/J8.jpg";
import J9 from "../../assets/Pshoot/New/J9.jpg";
import J10 from "../../assets/Pshoot/New/J10.jpg";
import J11 from "../../assets/Pshoot/New/J11.jpg";
import J12 from "../../assets/Pshoot/New/J12.jpg";
import J13 from "../../assets/Pshoot/New/J13.jpg";
import Z1 from "../../assets/Pshoot/New/Z1.jpg";
import Z2 from "../../assets/Pshoot/New/Z2.jpg";
import Z33 from "../../assets/Pshoot/New/Z3.jpg";

import L1 from "../../assets/Pshoot/New/L1.jpg";
import L2 from "../../assets/Pshoot/New/L2.jpg";
import L3 from "../../assets/Pshoot/New/L3.jpg";
import L4 from "../../assets/Pshoot/New/L4.jpg";
import L5 from "../../assets/Pshoot/New/L5.jpg";

import LS1 from "../../assets/Pshoot/New/LS1.jpg";
import LS2 from "../../assets/Pshoot/New/LS2.jpg";
import LS3 from "../../assets/Pshoot/New/LS3.jpg";
import LS4 from "../../assets/Pshoot/New/LS4.jpg";

import LW11 from "../../assets/Pshoot/New/LW11.jpg";
import LW22 from "../../assets/Pshoot/New/LW22.jpg";
import LW33 from "../../assets/Pshoot/New/LW33.jpg";
import LW44 from "../../assets/Pshoot/New/LW44.jpg";

import LG1 from "../../assets/Pshoot/New/LG1.jpg";
import LG2 from "../../assets/Pshoot/New/LG2.jpg";
import LG33 from "../../assets/Pshoot/New/LG33.jpg";

import LPS1 from "../../assets/Pshoot/New/LPS1.jpg";
import LPS2 from "../../assets/Pshoot/New/LPS2.jpg";


import PP1 from "../../assets/Pshoot/Pronto_Plus/pp1.jpg";
import PP2 from "../../assets/Pshoot/Pronto_Plus/pp2.jpg";
import PP3 from "../../assets/Pshoot/Pronto_Plus/pp3.jpg";
import PP4 from "../../assets/Pshoot/Pronto_Plus/pp4.jpg";
import PP5 from "../../assets/Pshoot/Pronto_Plus/pp5.jpg";
import PPB1 from "../../assets/Pshoot/Pronto_Plus/ppb1.jpg";
import PPB2 from "../../assets/Pshoot/Pronto_Plus/ppb2.jpg";
import PPB3 from "../../assets/Pshoot/Pronto_Plus/ppb3.jpg";

import FB1 from "../../assets/Pshoot/Franky/FB1.jpg";
import FB2 from "../../assets/Pshoot/Franky/FB2.jpg";
import FB3 from "../../assets/Pshoot/Franky/FB3.jpg";
import FB4 from "../../assets/Pshoot/Franky/FB4.jpg";
import FB5 from "../../assets/Pshoot/Franky/FB5.jpg";

export const products = [
  {
    id: 1,
    name: "PRONTO PLUS",
    images: {
      Silver: [PP1, PP2, PP3, PP4,PP5],
      Black: [PPB1, PPB2, PPB3, PP5],
    },
    featureImages: {
      Silver: [
        { image: PP1, title: "Silver Side View" },
        { image: PP2, title: "Silver Front View" },
        { image: PP3, title: "Silver Angle View" },
        { image: PP4, title: "Silver Side View" },
        { image: PP5, title: "Digital Meter" },
      ],
      Black: [
        { image: PPB1, title: "Black Side View" },
        { image: PPB2, title: "Black Side View" },
        { image: PPB3, title: "Black Side View" },
        { image: PP5, title: "Digital Meter" },

      ],
      
    },
    price: "199000",
    colors: ["Silver", "Black"],
    specifications: {
      Motor: "1200 W BLDC QS",
      Speed: "50-55 KM/H",
      Range: "80-90 KM",
      Battery: "72V 32 AH GRAPHENE",
      TyreSize: "10 INCH ALLOY RIM",
      Brake: "FRONT DISK REAR DRUM BRAKE",
      Connectivity: "Bluetooth-enabled for keyless ignition via mobile"
    },
    description: `The PRONTO PLUS electric bike is a reliable and efficient choice for everyday commuting. It’s equipped with a powerful 1200 W BLDC QS motor, allowing you to reach speeds of 50-55 km/h, making it perfect for city rides. The bike features a 72V 32 AH graphene battery, providing a solid range of 80-90 km, ensuring you can cover significant distances on a single charge. With 10-inch alloy rims, the PRONTO PLUS offers stability and a smooth ride on various road surfaces.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "50-55 Km/h",
      },
      {
        title: "Range",
        value: "80-90 KM",
      },
      {
        title: "Battery Type",
        value: "72V 32 AH GRAPHENE",
      },
      {
        title: "Electric Motor Power",
        value: "1200 Watts",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise control",
        value:
          "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value:
          "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
      {
        title: "Connectivity",
        value: "Bluetooth-enabled for keyless ignition via mobile",
      },
    ],
  }, 
  {
    id: 2,
    name: "LEOPARD",
    images: {
      Silver: [LS4,LS3,LS2,LS1,g1, g2, g3, g4],
      Green: [L5,L3,L2,L4,L1, gre1, gre2, gre3, gre4],
      White: [LW11,LW22,LW33,LW44,LW1, LW2, LW3],
    },
    featureImages: {
      Silver: [
        { image: Grstorage, title: "Storage Space" },
        { image: Grtyre, title: "12 Inch Alloy Rim Tyre" },
        { image: Grbrake, title: "Front & Rear Bigger Disk Brake" },
        { image: Grlamp, title: "Daylight Running Lamp" },
        { image: LMNew, title: "Digital Meter" },
        { image: LCnew, title: "Cruise Control" },


        { image: LS1, title: "Back Tire" },
        { image: LS2, title: "Back View" },
        { image: LS3, title: "Front View" },
        { image: LS4, title: "Side View" },

      ],
      Green: [
        { image: Fgrestorage, title: "Storage Space" },
        { image: Fgretyre, title: "12 Inch Alloy Rim Tyre" },
        { image: Fgrebrake, title: "Front & Rear Bigger Disk Brake" },
        { image: Fgrelamp, title: "Daylight Running Lamp" },
        { image: LMNew, title: "Digital Meter" },
        { image: LCnew, title: "Cruise Control" },

        { image: L1, title: "Back Tire" },
        { image: L2, title: "Back View" },
        { image: L3, title: "Front View" },
        { image: L4, title: "Digital Meter" },
        { image: L5, title: "Side View" },


      ],
      White: [
        { image: LW2, title: "Storage Space" },
        { image: LW3, title: "12 Inch Alloy Rim Tyre" },
        // { image: LW1, title: "Rear View" },

        { image: LW11, title: "Side View" },
        { image: LW22, title: "Front View" },
        { image: LW33, title: "Back View" },
        { image: LW44, title: "Back tire" },
        // { image: Fgrelamp, title: "Daylight Running Lamp" },
        // { image: LMNew, title: "Digital Meter" },
        // { image: LCnew, title: "Cruise Control" },
      ],
    },
    price: "260000",
    colors: ["Silver", "Green", "White"],
    specifications: {
      Motor: "1200 W BLDC QS",
      Speed: "50-55 KM/H",
      Range: "120 KM (ECO MODE)",
      Battery: "72V 38 AH GRAPHENE",
      TyreSize: "12 INCH ALLOY RIM",
      Brake: "FRONT & REAR BIGGER DISK",
      // ElectricMotorPower: "2000 Watts",
      // WarrantyPeriod:
      //   "24 Months Motor & 12 Months Batteries and Controller warranty",
      // TopSpeed: "70 Km/h",
    },
    description: `The LEOPARD electric bike is our main luxury product, offering a blend of style and power. It comes with a 1200 W motor, enabling speeds of 50-55 km/h. The bike features a 72V 38 AH graphene battery, providing a range of up to 120 km in eco mode. With 12-inch alloy rims, it ensures durability and stability. The front and rear bigger disk brakes offer enhanced safety. Available in grey, white and green, the bike’s sleek and modern design makes it perfect for city rides.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "50-55 Km/h",
      },
      {
        title: "Range",
        value: "120 KM (ECO MODE)",
      },
      {
        title: "Battery Type",
        value: "72V 38 Ah Graphene",
      },
      {
        title: "Electric Motor Power",
        value: "1200 Watts",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise control",
        value:
          "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value:
          "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
    ],
  },
  {
    id: 3,
    name: "JOY",
    images: {
      Black: [ J3,J2,  J4, J1],
      Red: [J9 , Rj1, Rj2 , J6, J7, J8],
      White: [Wj1],
      Grey: [J5, JG1 , J10, J11, J12, J13],

    },
    featureImages: {
      Black: [
        { image: J1, title: "Back Tire" },
        { image: J2, title: "Back View" },
        { image: J3, title: "Front View" },
        { image: J4, title: "Meter Close View" },
        { image: J5, title: "Side View" },
      ],
      Red: [
        // { image: Rstorage, title: "Storage Space" },
        { image: Jtyre, title: "Tyre" },
        { image: Rbrake, title: "Disk Brake" },
        { image: Rlamp, title: "Daylight Running Lamp" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },
        { image: J6, title: "Front View" },
        { image: J7, title: "Back Tire" },
        { image: J8, title: "Back View" },
        { image: J9, title: "Side View" },
      ],
      White: [
        { image: Jstorage, title: "Storage Space" },
        { image: Jbrake, title: "Tyre" },
        { image: diskbrake, title: "Disk Brake" },
        { image: daylight, title: "Daylight Running Lamp" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },
      ],
      Grey: [
        { image: Jstorage, title: "Storage Space" },
        { image: tyre, title: "Tyre" },
        { image: diskbrake, title: "Disk Brake" },
        { image: JGlamp, title: "Daylight Running Lamp" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },

        { image: J10, title: "Back View" },
        { image: J11, title: "Front View" },
        { image: J12, title: "Side View " },
        { image: J13, title: "Back Tire " },
      ],
     
    },
    price: "175000",
    colors: ["Grey" , 'Black',"White", "Red", ],
    specifications: {
      Motor: "1000 W BLDC QS",
      Speed: "45-50 KM/H",
      Range: "55-60 KM",
      Battery: "60V 26 AH GRAPHENE",
      TyreSize: "10 INCH ALLOY RIM",
      Brake: "FRONT DISK REAR DRUM BRAKE",
      // ElectricMotorPower: "2000 Watts",
      // WarrantyPeriod:
      //   "24 Months Motor & 12 Months Batteries and Controller warranty",
      // TopSpeed: "70 Km/h",
    },
    description: `The JOY electric bike is a versatile and affordable option, perfect for bachelors, students, and anyone looking for a practical mode of transportation. It features a 1000 W BLDC QS motor, enabling speeds of 40-45 km/h, making it well-suited for city commutes or casual rides. The bike is equipped with a 60V 26 AH graphene battery, providing a range of 55-60 km, ensuring you can easily cover your daily travel needs.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "40-45 Km/h",
      },
      {
        title: "Range",
        value: "55-60 KM",
      },
      {
        title: "Battery Type",
        value: "60V 26 AH GRAPHENE",
      },
      {
        title: "Electric Motor Power",
        value: "1000 Watts",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise control",
        value:
          "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value:
          "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
    ],
  },
  {
    id: 4,
    name: "LEOPARD PRO",
    images: {
      Green: [LG1,LG2,LG33 ,LP1, LP2],
      Silver: [LPS2,LPS1,LP4],
      White: [LW11,LW22,LW33,LW44,],
    },
    featureImages: {
      Silver: [
        { image: LP4, title: "Back view" },
        { image: diskbrake, title: "Disk Brake" },
        { image: daylight, title: "Daylight Running Lamp" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },

        { image: LPS2, title: "side View" },
        { image: LPS1, title: "Front View" },
      ],
      Green: [
        { image: Jstorage, title: "Storage Space" },
        { image: tyre, title: "Tyre" },
        { image: diskbrake, title: "Disk Brake" },
        { image: LP3, title: "Tyre and disk brake" },
        { image: LP5, title: "Handle and meter view" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },

        { image: LG1, title: "Side View" },
        { image: LG2, title: "Front View" },
        { image: LG33, title: "Back Tire" },

      ],
      White: [

        { image: LP4, title: "Back view" },
        { image: diskbrake, title: "Disk Brake" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },

        
        { image: LW11, title: "Side View" },
        { image: LW22, title: "Front View" },
        { image: LW33, title: "Back View" },
        { image: LW44, title: "Back tire" },
      ],
    },
    price: "280000",
    colors: ["Green", "Silver", "White"],
    specifications: {
      Motor: "2000 W QS World Top Class",
      Speed: "70 KM/H",
      Battery: "72V 38 AH Chilwee Brand GOLD GRAPHENE",
      TyreSize: "12 INCH ALLOY RIM",
      Brake: "FRONT DISK REAR DISK BRAKE",

      // ElectricMotorPower: "2000 Watts",
      // WarrantyPeriod:
      //   "24 Months Motor & 12 Months Batteries and Controller warranty",
      // TopSpeed: "70 Km/h",
    },
    description: `The Leopard Pro electric bike is a versatile and affordable option, perfect for bachelors, students, and anyone looking for a practical mode of transportation. It features a 2000 W BLDC QS motor, enabling speeds of 70 km/h, making it well-suited for city commutes or casual rides. The bike is equipped with a 72V 38 AH graphene battery, ensuring you can easily cover your daily travel needs.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "70 Km/h",
      },
      {
        title: "Battery Type",
        value: "72V 38 AH GOLD GRAPHENE",
      },
      {
        title: "Battery Management System",
        value: "Available",
      },
      {
        title: "EBS Regenration System",
        value: "Available",
      },
      {
        title: "Anti-theft System",
        value: "Available",
      },
      {
        title: "Electric Motor Power",
        value: "2000 Watts",
      },
      {
        title: "Ground Clearence",
        value: "172 mm",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise control",
        value:
          "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value:
          "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
    ],
  },
  {
    id: 5,
    name: "ZIPPY",
    images: {
      Blue: [Z4, Z3, Z5, Z6, Z7, Z8],
      Black: [ZB1, ZB2 ,Z1 ,Z2,Z33],
    },
    featureImages: {
      Blue: [
        // { image: Jstorage, title: "Storage Space" },
        // { image: tyre, title: "Tyre" },
        // { image: diskbrake, title: "Disk Brake" },
        { image: Z3, title: "Handle and meter view" },
        { image: Z4, title: "Full Side view" },
        { image: Z5, title: "Front Side view" },

        { image: Z6, title: "Top view" },
        { image: Z7, title: "Head-light and front storage" },
        { image: Z8, title: "Tyre and Bottom view" },

        // { image: digitalmeter, title: "Digital Meter" },
        // { image: cruidecontrol, title: "Cruise Control" },
      ],
      Black: [
        { image: ZB1, title: "Front view" },
        { image: ZB2, title: "Tyre and Storage view" },
        { image: Z1, title: "Back Tire" },
        { image: Z2, title: "Back View" },
        { image: Z33, title: "Side View" },
        // { image: cruidecontrol, title: "Cruise Control" },
      ],
    },
    price: "97000",
    colors: ["Blue", "Black"],
    specifications: {
      Motor: "350 W Pure Copper Mottor",
      Speed: "30 - 35 KM/H",
      Battery: "48V 12AH Top brand Chilwee",
      Loading: "80 KG",
      Range: "35 - 40 KM",
      Brake: "DRUM",
    },
    description: `The Zippy electric bike is a versatile and very affordable option, perfect for bachelors, students, and anyone looking for a practical mode of transportation. It features a 350 W motor, enabling speeds of 35 km/h, making it well-suited for city commutes or casual rides. The bike is equipped with a 48V 12AH Chilwee Brand battery, ensuring you can easily cover your daily travel needs.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "30 - 35 Km/h",
      },
      {
        title: "Range",
        value: "35 - 40 Km",
      },
      {
        title: "Battery Type",
        value: "48V 12AH top brand Chilwee",
      },
      {
        title: "Cost",
        value: "0.5 Unit / Charging",
      },
      {
        title: "Anti-theft System",
        value: "Available",
      },
      {
        title: "Electric Motor Power",
        value: "350 Watts",
      },
      {
        title: "Loading",
        value: "80 kg",
      },
      {
        title: "Warranty Period",
        value: "12 Months Motor & 06 Months Batteries and Controller warranty",
      },
      {
        title: "Speed Ride",
        value: "3 Modes available",
      },
    ],
  },
  {
    id:6,
    name: "FLORA",
    images: {
      Pink: [F2, F7,F5,  F6],
      Yellow: [F3, F4, F6],
    },
    featureImages: {
      Pink: [
        // { image: Jstorage, title: "Storage Space" },
        // { image: tyre, title: "Tyre" },
        // { image: diskbrake, title: "Disk Brake" },
        { image: F1, title: "Back Tire view" },
        { image: F2, title: "Full Side view" },
        { image: F5, title: "Back Side view" },
        { image: F6, title: "Cruise view" },

        // { image: Z6, title: "Top view" },
        // { image: Z7, title: "Head-light and front storage" },
        // { image: Z8, title: "Tyre and Bottom view" },

        // { image: digitalmeter, title: "Digital Meter" },
        // { image: cruidecontrol, title: "Cruise Control" },
      ],
      Yellow: [
        { image: F3, title: " Side view" },
        { image: F4, title: "Front  view" },
        { image: F6, title: "Cruise view" },

        // { image: diskbrake, title: "Disk Brake" },
        // { image: daylight, title: "Daylight Running Lamp" },
        // { image: digitalmeter, title: "Digital Meter" },
        // { image: cruidecontrol, title: "Cruise Control" },
      ],
    },
    price: "153000",
    colors: ["Pink", "Yellow"],
    specifications: {
      Motor: "600 W Pure Copper Mottor",
      Speed: "45 KM/H",
      Battery: "60V 23AH Top brand Chilwee",
      // Loading: "80 KG",
      Range: "55 - 60 KM",
      Brake: "FRONT DISK REAR DISK BRAKE",
    },
    description: `The FLORA electric bike is a versatile and affordable option, perfect for bachelors, students, and anyone looking for a practical mode of transportation. It features a  W BLDC QS motor, enabling speeds of 40-45 km/h, making it well-suited for city commutes or casual rides. The bike is equipped with a 60V 26 AH graphene battery, providing a range of 55-60 km, ensuring you can easily cover your daily travel needs.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "45 Km/h",
      },
      {
        title: "Range",
        value: "55 - 50 Km",
      },
      {
        title: "Battery Type",
        value: "60V 23AH top brand Chilwee",
      },
      // {
      //   title: "Cost",
      //   value: "0.5 Unit / Charging",
      // },
      // {
      //   title: "Anti-theft System",
      //   value: "Available",
      // },
      {
        title: "Electric Motor Power",
        value: "600 Watts",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise control",
        value: "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value: "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
    ],
  },
  {
    id:7,
    name: "PEARL",
    images: {
      Green: [P9, P2, P3, P4 , P6 , P9,P14 ],
      Silver: [P12,P2, P5 , P11 , P13],
    },
    featureImages: {
      Green: [
        // { image: Jstorage, title: "Storage Space" },
        // { image: tyre, title: "Tyre" },
        // { image: diskbrake, title: "Disk Brake" },
        { image: P9, title: "Side view" },
        { image: P2, title: "Cruise view" },
        { image: P3, title: "Front view" },
        { image: P4, title: "Back Tire" },
        { image: P6, title: "Back view" },
        { image: P9, title: "Full Side view" },
        { image: P14, title: "Back Tire" },

        // { image: P10, title: "Back Side view" },
        // { image: F6, title: "Cruise" },
        // { image: Z6, title: "Top view" },
        // { image: Z7, title: "Head-light and front storage" },
        // { image: Z8, title: "Tyre and Bottom view" },

        // { image: digitalmeter, title: "Digital Meter" },
        // { image: cruidecontrol, title: "Cruise Control" },
      ],
      Silver: [
        { image: P12, title: " Side view" },
        { image: P2, title: "Cruise view" },
        { image: P5, title: "Back Tire" },
        { image: P11, title: "Back  view" },
        { image: P13, title: "Front View" },
        // { image: diskbrake, title: "Disk Brake" },
        // { image: daylight, title: "Daylight Running Lamp" },
        // { image: digitalmeter, title: "Digital Meter" },
        // { image: cruidecontrol, title: "Cruise Control" },
      ],
    },
    price: "159000",
    colors: ["Silver", "Green"],
    specifications: {
      Motor: "800 W Pure Copper Mottor",
      Speed: "45 - 50 KM/H",
      Battery: "60V 23AH Top brand Chilwee",
      // Loading: "80 KG",
      Range: "60 - 65 KM",
      Brake: "FRONT DISK REAR DISK BRAKE",
    },
    description: `The PEARL electric bike is a versatile and affordable option, perfect for bachelors, students, and anyone looking for a practical mode of transportation. It features a  W BLDC QS motor, enabling speeds of 40-45 km/h, making it well-suited for city commutes or casual rides. The bike is equipped with a 60V 23 AH graphene battery, providing a range of 60-65 km, ensuring you can easily cover your daily travel needs.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "45 Km/h",
      },
      {
        title: "Range",
        value: "60 - 65 Km",
      },
      {
        title: "Battery Type",
        value: "60V 23AH top brand Chilwee",
      },
      // {
      //   title: "Cost",
      //   value: "0.5 Unit / Charging",
      // },
      // {
      //   title: "Anti-theft System",
      //   value: "Available",
      // },
      {
        title: "Electric Motor Power",
        value: "800 Watts",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise control",
        value: "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value: "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
    ],
  },
 
  {
    id: 8,
    name: "PRONTO",
    images: {
      Red: [r1, r3, r2, r4],
      White: [w1, w2, w3, w4],
      Grey: [Gp, Gpstorage, Gpbrake, Gptyre],
    },
    featureImages: {
      Red: [
        { image: Rstorage, title: "Storage Space" },
        { image: Rtyre, title: "10 Inch Alloy Rim Tyre" },
        { image: Rbrake, title: "Front Disk Rear Drum Brake" },
        { image: Rlamp, title: "Daylight Running Lamp" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },
      ],
      White: [
        { image: storage, title: "Storage Space" },
        { image: tyre, title: "10 Inch Alloy Rim Tyre" },
        { image: diskbrake, title: "Front Disk Rear Drum Brake" },
        { image: daylight, title: "Daylight Running Lamp" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },
      ],
      Grey: [
        { image: Gpstorage, title: "Storage Space" },
        { image: Gptyre, title: "10 Inch Alloy Rim Tyre" },
        { image: Gpbrake, title: "Front Disk Rear Drum Brake" },
        { image: Gplamp, title: "Daylight Running Lamp" },
        { image: digitalmeter, title: "Digital Meter" },
        { image: cruidecontrol, title: "Cruise Control" },
      ],
    },
    price: "199000",
    colors: ["White", "Red", "Grey"],
    specifications: {
      Motor: "1200 W BLDC QS",
      Speed: "50-55 KM/H",
      Range: "80-90 KM",
      Battery: "72V 32 AH GRAPHENE",
      TyreSize: "10 INCH ALLOY RIM",
      Brake: "FRONT DISK REAR DRUM BRAKE",
      // ElectricMotorPower: "2000 Watts",
      // WarrantyPeriod:
      //   "24 Months Motor & 12 Months Batteries and Controller warranty",
      // TopSpeed: "70 Km/h",
    },
    description: `The PRONTO electric bike is a reliable and efficient choice for everyday commuting. It’s equipped with a powerful 1200 W BLDC QS motor, allowing you to reach speeds of 50-55 km/h, making it perfect for city rides. The bike features a 72V 32 AH graphene battery, providing a solid range of 80-90 km, ensuring you can cover significant distances on a single charge. With 10-inch alloy rims, the PRONTO offers stability and a smooth ride on various road surfaces.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "50-55 Km/h",
      },
      {
        title: "Range",
        value: "80-90 KM",
      },
      {
        title: "Battery Type",
        value: "72V 32 AH GRAPHENE",
      },
      {
        title: "Electric Motor Power",
        value: "1200 Watts",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise control",
        value:
          "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value:
          "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
    ],
  },
  {
    id: 9,
    name: "FRANKY",
    images: {
      Black: [FB2, FB5, FB1, FB4, FB3],
      White: [FB5, FB2, FB1, FB4, FB3],
      Blue: [FB1, FB5, FB1, FB4, FB3],
    },
    featureImages: {
      Black: [
        { image: FB5, title: "Side View" },
        { image: FB2, title: "Side View" },
        { image: FB1, title: "Front View" },
        { image: FB4, title: "Tyre View" },
        { image: FB3, title: "Digital Meter" },
      ],
      White: [
        { image: FB5, title: "Side View" },
        { image: FB2, title: "Side View" },
        { image: FB1, title: "Front View" },
        { image: FB4, title: "Tyre View" },
        { image: FB3, title: "Digital Meter" },
      ],

      Blue: [
        { image: FB5, title: "Side View" },
        { image: FB2, title: "Side View" },
        { image: FB1, title: "Front View" },
        { image: FB4, title: "Tyre View" },
        { image: FB3, title: "Digital Meter" },
      ],
    },
    price: "130000",
    colors: ["Black","White",  "Blue"],
    specifications: {
      Motor: "600W BLDC QS",
      Speed: "35-40 KM/H",
      Range: "55-60 KM",
      Battery: "48V 23 AH GRAPHENE",
      TyreSize: "10 INCH ALLOY RIM",
      Brake: "FRONT DISK REAR DRUM BRAKE",
    },
    description: `The Franky Model electric scooter is designed for both performance and style. 
    With a powerful 600W BLDC QS motor, it delivers a smooth and efficient ride, reaching speeds of up to 40 km/h. 
    Its 48V 23AH Graphene battery ensures a long-lasting range of 55-60 km per charge. 
    Equipped with a 10-inch alloy rim, front disc brake, and rear drum brake, this scooter offers superior safety and control. 
    Features like cruise control, reverse gear, and a side stand sensor make it an ideal choice for urban commuting. 
    Available in three stylish colors—White, Black, and Blue—Franky Model redefines modern transportation.`,
    detailedSpecifications: [
      {
        title: "Speed",
        value: "35-40 Km/h",
      },
      {
        title: "Range",
        value: "55-60 KM",
      },
      {
        title: "Battery Type",
        value: "48V 23 AH GRAPHENE",
      },
      {
        title: "Electric Motor Power",
        value: "600 Watts",
      },
      {
        title: "Warranty Period",
        value: "24 Months Motor & 18 Months Batteries and Controller warranty",
      },
      {
        title: "Cruise Control",
        value: "Maintains a set speed, freeing the rider from constantly pressing the throttle.",
      },
      {
        title: "Reverse Gear",
        value: "Easily back up the scooter, making parking and tight turns a breeze.",
      },
      {
        title: "Side Stand Sensor",
        value: "Available",
      },
    ],
  }
    
];
