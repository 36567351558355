import React from 'react';
import { Heading, Stack, Text } from '@chakra-ui/react';
import Carousel from '../slider/Carousel';
import Layout from '../layout/Layout';

import FB1 from "../../assets/Pshoot/Franky/FB1.jpg";
import FB2 from "../../assets/Pshoot/Franky/FB2.jpg";
import FB3 from "../../assets/Pshoot/Franky/FB3.jpg";
import FB4 from "../../assets/Pshoot/Franky/FB4.jpg";
import FB5 from "../../assets/Pshoot/Franky/FB5.jpg";

const FrankyDetail = () => {
  return (
    <Layout>
      <Stack width={'100%'} className="telex-regular">
        <Stack alignItems={'center'} p={10} gap={4}>
          <Heading fontSize={{ base: '25px', md: '25px', lg: '33px' }} fontWeight={'500'} color={'#0b8c29'}>
            Introducing Franky Model
          </Heading>
          <Text fontSize={{ base: '15px', md: '15px', lg: '15px' }} fontWeight={'400'} color={'#000'} textAlign={'center'} w={{ base: '100%', md: '70%' }}>
          Revolutionizing the transport industry in Pakistan. We are proud to offer a high-quality, reliable, and convenient transportation solution that is perfect for a wide range of needs.
          </Text>
        </Stack>

        <Carousel images={[FB1, FB2, FB3, FB4, FB5]} />

        <Stack gap={4} alignItems={'center'} textAlign={'center'} px={10}>
          <Heading fontSize={{ base: '25px', md: '25px', lg: '33px' }} fontWeight={'500'} color={'#0b8c29'}>
            Choose Your Preference
          </Heading>
          <Text fontSize={{ base: '15px', md: '15px', lg: '15px' }} fontWeight={'400'} color={'#000'} textAlign={'center'} w={{ base: '100%', md: '70%' }}>
            Available in three stylish colors: White, Black, and Blue. Choose the one that best suits your personality.
          </Text>
        </Stack>

        <Stack gap={4} py={10} alignItems={'center'} textAlign={'center'} px={10}>
          <Heading fontSize={{ base: '25px', md: '25px', lg: '33px' }} fontWeight={'500'} color={'#0b8c29'}>
            FEATURES
          </Heading>
        </Stack>

        <Stack w={'100%'} direction={{ base: 'column', md: 'row' }} px={'8%'} pb={10} gap={10}>
          <Stack width={{ base: '100%', md: '50%' }}>
            <Stack gap={10}>
              <Heading fontSize={{ base: '25px', md: '25px', lg: '33px' }} fontWeight={'400'} color={'black'}>
                Powerful Motor
              </Heading>
              <Text fontSize={{ base: '15px', md: '15px', lg: '15px' }} fontWeight={'400'} color={'#000'} lineHeight={'35px'}>
                The Franky Model features a 600W BLDC QS motor, delivering a top speed of 40 km/h with excellent torque and performance.
              </Text>
            </Stack>
          </Stack>
          <Stack width={{ base: '100%', md: '50%' }} gap={10}>
            <Heading fontSize={{ base: '25px', md: '25px', lg: '33px' }} fontWeight={'400'} color={'black'}>
              Comfort & Stability
            </Heading>
            <Text fontSize={{ base: '15px', md: '15px', lg: '15px' }} fontWeight={'400'} color={'#000'} lineHeight={'35px'}>
              Equipped with 10-inch alloy rims and front disc brakes, the Franky Model provides excellent grip and stopping power.
              Hydraulic shock absorbers ensure a smooth and comfortable ride.
            </Text>
          </Stack>
        </Stack>

        <Stack gap={10} w={'100%'} direction={{ base: 'column', md: 'row' }} px={'8%'} pb={10}>
          <Stack width={{ base: '100%', md: '50%' }} gap={10}>
            <Heading fontSize={{ base: '25px', md: '25px', lg: '33px' }} fontWeight={'400'} color={'black'}>
              Battery & Charging
            </Heading>
            <Text fontSize={{ base: '15px', md: '15px', lg: '15px' }} fontWeight={'400'} color={'#000'} lineHeight={'35px'}>
              The Franky Model is powered by a 48V 23AH Graphene battery, taking approximately 5-6 hours for a full charge. It provides a range of 55-60 km on a single charge.
            </Text>
          </Stack>
          <Stack width={{ base: '100%', md: '50%' }} gap={10}>
            <Heading fontSize={{ base: '25px', md: '25px', lg: '33px' }} fontWeight={'400'} color={'black'}>
              Additional Features
            </Heading>
            <Text fontSize={{ base: '15px', md: '15px', lg: '15px' }} fontWeight={'400'} color={'#000'} lineHeight={'35px'}>
              Includes cruise control, reverse gear for easy parking, and a side stand sensor for safety.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default FrankyDetail;
