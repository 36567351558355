// import React from 'react';
// import {
//   Stack,
// } from '@chakra-ui/react';
// import banner from '../../assets/images/banner.jpg';
// import bannerMobile from '../../assets/images/banner-mobile.jpg';
// import banner2 from '../../assets/images/banner2.jpg';
// import bannerMobile2 from '../../assets/images/banner3.jpg';

// const Carousal = () => {
//   const isMobile = window.innerWidth >= 600;

//   return (
//     <>
//       <Stack
//       className='telex-regular'
//         width={'full'}
//         px={{ base: 4,md:8, lg: 28 }}
//         py={{ base: 4,md:8, lg: 2 }}
//         bgImage={`url(${isMobile ? bannerMobile : banner})`}
//         bgSize={'cover'} // Set background size to cover
//         bgPosition={'center'}
//         h={{base:'90vh',lg:'100vh'}}
//       >
//         {/* <Stack
//           width={'100%'}
//           direction={{ base: 'row', md: 'row' }}
//           alignItems={'center'}
//           justifyContent={'space-between'}
//         >
        
          
//           <Stack
//             display={{ base: 'none', md: 'none', lg: 'inherit' }}
//             px={10}
//             py={2}
//             direction={'row'}
//             gap={{ base: '20px', lg: '80px', xl: 12 }}
//           >
          
            
           
//           </Stack>
//         </Stack> */}
//       </Stack>
//     </>
//   );
// };

// export default Carousal;




// import React, { useState, useEffect } from 'react';
// import { Stack } from '@chakra-ui/react';
// import banner from '../../assets/images/banner.jpg';
// import bannerMobile from '../../assets/images/banner-mobile.jpg';
// import banner2 from '../../assets/images/banner2.jpg';
// import bannerMobile2 from '../../assets/images/banner4.jpg';
// import bannerMobile3 from '../../assets/images/banner5.jpg'; // Added third mobile banner

// const Carousal = () => {
//   const [currentBanner, setCurrentBanner] = useState(banner); // Default desktop banner image
//   const [currentMobileBanner, setCurrentMobileBanner] = useState(bannerMobile); // Default mobile banner image
//   const isMobile = window.innerWidth < 600; // Check if it's a mobile device

//   // Change banner image every 2 seconds
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (isMobile) {
//         // Cycle through 3 mobile images
//         setCurrentMobileBanner((prev) => {
//           if (prev === bannerMobile) return bannerMobile2;
//           if (prev === bannerMobile2) return bannerMobile3;
//           return bannerMobile; // Return to the first image if it's the third
//         });
//       } else {
//         // Cycle through 2 desktop images
//         setCurrentBanner((prev) => (prev === banner ? banner : banner));


//         // ==============================================================================




//         // setCurrentBanner((prev) => (prev === banner ? banner2 : banner));




//         // =================================================================================



//       }
//     }, 5000); // Change every 2 seconds

//     // Cleanup interval on component unmount
//     return () => clearInterval(intervalId);
//   }, [isMobile]);

//   return (
//     <Stack
//       className="telex-regular"
//       width={'full'}
//       px={{ base: 4, md: 8, lg: 28 }}
//       py={{ base: 4, md: 8, lg: 2 }}
//       bgImage={`url(${isMobile ? currentMobileBanner : currentBanner})`}
//       bgSize={'cover'}
//       bgPosition={'center'}
//       h={{ base: '90vh', lg: '100vh' }}
//       transition="background-image 1s ease-in-out" // Smooth background change animation
//     >
//       {/* Content goes here */}
//     </Stack>
//   );
// };

// export default Carousal;
import React, { useState, useEffect } from 'react';
import { Stack } from '@chakra-ui/react';

import banner1 from '../../assets/images/banner.jpg';
import banner2 from '../../assets/images/banner7.png';

import bannerMobile1 from '../../assets/images/banner-mobile.jpg';
import bannerMobile2 from '../../assets/images/banner4.jpg';
import bannerMobile3 from '../../assets/images/banner5.jpg';
import bannerMobile4 from '../../assets/images/banner6.png';

const desktopBanners = [banner1, banner2];
const mobileBanners = [bannerMobile1, bannerMobile2, bannerMobile3, bannerMobile4];

const Carousel = () => {
  const isMobile = window.innerWidth < 600;
  const banners = isMobile ? mobileBanners : desktopBanners;
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(1); // Opacity control

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(0); // Fade out

      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % banners.length);
        setFade(1); // Fade in
      }, 500); // Wait before switching image
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <Stack
      className="telex-regular"
      width="full"
      px={{ base: 4, md: 8, lg: 28 }}
      py={{ base: 4, md: 8, lg: 2 }}
      bgImage={`url(${banners[index]})`}
      bgSize="cover"
      bgPosition="center"
      h={{ base: '90vh', lg: '100vh' }}
      transition="opacity 0.8s ease-in-out"
      opacity={fade}
    >
      {/* Content Goes Here */}
    </Stack>
  );
};

export default Carousel;
